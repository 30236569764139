@import 'Colors.scss';

.ion-page[data-page="studyset"] {

    .cards {
        padding: 0 15px;
    }

    .background {
        background-color: whitesmoke;
        padding-bottom: 100px;
        height: 100%;
        overflow: auto;
    }

    .details {
        background-color: whitesmoke;

        .delete button {
            background-color: $red;
        }

        .field {
            background-color: white;
            border-radius: 12px;
            margin: 0 15px;
            padding: 4px 14px;
        }

        .suggestions {
            padding: 15px 15px 5px 15px;

            ion-chip {
                padding: 6px 10px;
                margin-right: 8px;
                margin-bottom: 8px;

                ion-label {
                    color: $light-black-2;
                }

            }

        }

        button {
            color: white;
            margin: 15px 15px 0 15px;
            border-radius: 10px;
            padding: 16px 15px;
            font-size: 1em;
            width: calc(100% - 30px);
            background-color: $blue-primary;

            &.delete {
                background-color: $red;
            }
        }

    }

    .search-filters {
        position: relative;
    }

    .search {
        position: relative;

        ion-searchbar {
            padding-inline-start: 15px;
            padding-inline-end: 15px;
            padding-top: 0;
            height: 48px;
        }

        .sort-btn {
            position: absolute;
            padding: 3px 7px;
            top: 3px;
            right: 18px;
            background-color: white;
            box-shadow: $default-box-shadow-3;
            border-radius: 8px;
            display: inline-flex;
            align-items: center;

            span {
                font-size: 0.9em;
                margin-right: 4px;
            }

        }

    }

    .description {
        padding: 10px 15px 0 15px;
        font-size: 1.1em;
        line-height: 1.3em;
    }

    .tags {
        padding: 10px 15px 0 15px;
        font-size: 1.1em;
        line-height: 1.3em;
    }

    .owner-details {
        padding: 15px 15px 0 15px;
        display: flex;
        align-items: center;
        justify-content: flex-start;

        span {
            // display: block;
            line-height: 1;
            margin-bottom: 5px;
        }

        label {
            // display: block;
            line-height: 1;
            margin-left: 6px;
            font-weight: 700;
        }

        img {
            width: 32px;
            height: 32px;
            border-radius: 100%;
            display: block;
        }

        img, svg {
            margin: 0 10px 0 0;
        }
    }

    .filters {
        margin: 4px 15px 0 15px;

        ion-chip {
            padding: 6px 8px;

            // &:first-of-type {
            //     font-size: 0.94rem;
            // }

            span {
                margin-left: 4px;
                font-size: 0.9em;
                font-weight: 800;
            }

            &:last-child {
                margin-right: 0;
            }

        }

        ion-icon {
            color: white;
        }

    }

    .header-main {
        padding: 12px 15px 0 15px;
        position: relative;

        h2 {
            font-weight: 700;
            margin-bottom: 4px;
            line-height: 1.3em;
            font-size: 1.5em;
            padding-right: 80px;

            button {
                position: absolute;
                right: 15px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                padding: 4px 8px;
                border: none;
                background-color: white;
                color: #333;
                top: 12px;
                border-radius: 6px;
                box-shadow: $default-box-shadow-3;

                ion-icon {
                    font-size: 20px;
                    margin-right: 6px;
                    display: inline-block;
                }
            }
        }

        p {
            line-height: 1em;
            display: flex;
            align-items: center;

            img {
                width: 32px;
                height: 32px;
                margin-right: 8px;
            }

            span:nth-of-type(2) {
                display: inline-block;
                margin: 0 8px;
            }
        }
    }

    .header {
        margin: 20px 0 10px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px;

        h2 {
            margin: 0;
            font-size: 1em;
            font-weight: 400;
        }

    }

    .current-filter {
        margin: 5px 15px 20px 15px;
        border-radius: 4px;
        font-weight: 500;
        text-align: center;
        text-transform: capitalize;
        align-items: center;
        justify-content: center;
        display: flex;

        label {
            margin-right: 4px;

            span {
                margin-left: 4px;
                font-weight: 700;
            }

        }

        ion-icon {
            font-size: 1.2em;
        }

    }

}

.sort-picker-modal {

    h2 {
        text-align: center;
    }

    .selector {

        button {
            background-color: whitesmoke;
            padding: 12px !important;
            border-radius: 6px;

            &[data-selected="true"] {
                // background-color: $blue-primary;
                background-color: gainsboro;
                // color: white;
                font-weight: 700;
            }

            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }

        &.vertical {

            button {
                width: 100%;
            }

        }

        &.horizontal {
            display: flex;
            margin-bottom: 20px;

            button {
                width: 50%;
                margin: 0;

                &:first-child {
                    margin-right: 12px;
                }
            }

        }

    }

    .main {
        width: calc(100% - 30px);
        margin: 0 15px 15px 15px;
    }

}