@import "./Colors.scss";

.practice-end-modal {
    background: $blue-primary !important;
    color: white;

    h2 {
        text-align: center;
        margin: 32px 0 32px 0;
        font-size: 2em;
        font-weight: 700;
        font-family: "Epilogue";
    }

    p, .new, .total {
        font-family: "Epilogue";
    }

    .gif {
        position: relative;
        overflow: hidden;
        border-radius: 12px;
        margin-bottom: 15px;

        img {
            width: 100%;
            display: block;
        }
    }
    
    .opts {
        display: flex;
        align-items: center;
        justify-content: space-around;

        button {
            background-color: white;
            color: #333;
            padding: 14px 8px;
            flex-grow: 1;
            flex-basis: 33.3333%;
            border-radius: 10px;
            font-size: 16px;
            
            ion-icon {
                margin-right: 5px;
            }

            span {
                display: inline-block;
                line-height: 16px;
                position: relative;
                top: -1px;
            }

            &:not(:last-child) {
                margin-right: 15px;
            }
        }
    }

    .body {

        .progresses {

            .progress {
                position: relative;
                width: 100%;
                height: 44px;
                border-radius: 52px;
                background-color: white;
                box-shadow: $default-box-shadow-3;
                max-width: 340px;
                margin: 0 auto 44px auto;

                .img {
                    position: absolute;
                    top: -13px;
                    left: -5px;
                    width: 48px;
                    z-index: 2;

                    img {
                        width: 100%;
                    }

                    span {
                        position: absolute;
                        top: 12px;
                        font-weight: 700;
                        font-size: 24px;
                        display: block;
                        text-align: center;
                        width: 48px;
                        top: 18px;
                    }

                    &.shield {
                        top: -11px;
                        width: 52px;
                        
                        span {
                            width: 52px;
                            top: 9px;
                        }
                    }

                }

                .done {
                    background-color: $green;
                    position: absolute;
                    z-index: 1;
                    border-radius: 52px;
                    height: 34px;
                    top: 5px;
                    left: 5px;
                    width: 0px;
                    transition: all 1s;
                    box-shadow: $default-box-shadow-3;
                }

                .text {
                    position: absolute;
                    width: 100%;
                    padding: 2px 0;
                    color: #333;
                    z-index: 2;
                    text-align: center;
                    font-weight: 500;
                    font-size: 18px;
                    top: 5px;
                }
            }
        }

        .highlights {
            width: 340px;
            display: flex;
            margin: 0 auto;
        }

        .stat {
            display: inline-block;
            margin: 0 0 20px 0;
            width: 113px;
            text-align: center;

            ion-icon {
                font-size: 32px;
            }

            p {
                font-weight: 600;
                font-size: 18px;
                text-align: center;
                margin: 2px 0 6px 0;
                padding: 0;
            }

            .new {
                line-height: 24px;
                min-width: 94px;
                height: 32px;
                border-radius: 52px;
                padding: 4px 6px 7px 6px;
                background-color: white;
                color: $blue-primary;
                font-weight: 700;
                display: inline-block;
                margin: 6px 0 0px 0;
                box-shadow: $default-box-shadow-3;
                font-size: 1.4em;
            }

            button {
                background: white;
                margin: 0;
                padding: 16px 32px;
                border-radius: 52px;
                font-weight: 700;
                color: $blue-primary;
            }

            .total {
                font-weight: 700;
                font-family: "Epilogue";
                font-size: 32px;
                line-height: 32px;
                margin: 6px 0 0px 0;
            }
        }
    }
}