@import 'Colors.scss';

.dropbox-auth-modal {

    h2 {
        padding: 15px 15px 0px 15px;
        font-weight: 700;
        font-size: 1.4em;
    }

    button {
        margin: 15px !important;
    }

    .status {
        margin: 15px;
        font-weight: 500;
    }

}
