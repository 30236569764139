.help-overlay {
    z-index: 10000;
    background-color: rgba(0, 0, 0, 0.9);
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &>button {
        background-color: white;
        color: #333;
        top: 20px;
        right: 20px;
        position: absolute;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 52px;
        border: none;
        padding: 8px;

        span {
            font-size: 1.1em;
            margin-right: 4px;
            display: none;
        }

        ion-icon {
            font-size: 2em;
        }
    }

    .shake {
        position: absolute;
        // width: 220px;
        // top: 240px;
        // left: 50%;
        border-radius: 12px;
        left: 20px;
        top: 20px;
        // transform: translate(-50%, 0);
        color: white;
        display: flex;
        align-items: center;
        justify-content: center;
        width: calc(100% - 92px);
        line-height: 1.2em;
        height: 42px;

        img {
            width: 40px;
            margin-right: 16px;
        }
    }

}

.arrow-wrapper {
    display: inline-flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    z-index: 100001;

    img {
        width: 40px;
        height: 40px;
        max-width: unset;
    }

    label {
        margin-left: 12px;
        color: white;
        position: absolute;
        left: 48px;
        top: -7px;
        width: 80px;
        font-size: 17px;
        line-height: 19px;
        margin: 0;
        text-align: left;
    }

    &[data-direction="left-top"] {
        img {
            transform: rotate(180deg) rotate3d(0, 1, 0, 180deg);
        }

        label {
            top: 30px;
        }
        
    }

    &[data-direction="right-bottom"] {

        label {
            text-align: right;
            right: 52px;
            left: auto;
        }

        img {
            transform: rotate3d(0, 1, 0, 180deg);
        }
    }

    &[data-direction="right-top"] {

        label {
            right: 52px;
            left: auto;
            top: 28px;
            text-align: right;
        }
        
        img {
            transform: rotate(180deg);
        }

    }
}

body.help-overlay-showing {

    .MuiBackdrop-root {
        background-color: rgba(0, 0, 0, 0.5) !important;
    }

    ion-tab-bar {
        display: none;
    }

    ion-content {
        z-index: 100000;
        position: fixed;

        .background {
            padding: 50px 0
        }
    }

    .flashcard-editor-modal {
        margin: 0 0 40px 0 !important;

        .help-overlay {
            background-color: rgba(0,0,0,0.8) !important;
        }
    }

}