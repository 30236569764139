@import 'Colors.scss';

.ion-page[data-page="settings"] {

    ion-list {
        padding-bottom: 15px;
    }

    ion-list-header {
        height: 68px;
    }

    ion-content>div {
        min-height: 100%;
    }

    ion-item.long {

        ion-label:last-child {
            text-align: right;
        }

        &.sync-now {
            ion-label {
                text-align: left;
            }
        }

    }

    ion-item {

        ion-icon {
            margin-inline-end: 0
        }

    }

    ion-list ion-item:last-child {
        --border-color: transparent !important;
    }

    p {
        padding: 0 20px;
        margin-top: -5px;
        margin-bottom: 15px;
        font-weight: 300;
    }

}
