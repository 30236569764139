@import 'Colors.scss';

.ion-page[data-page="flashcards"] {

    ion-toolbar {
        height: 0;
        --border-width: 0;
        overflow: hidden;
    }

    .activities {
        position: relative;

        .autoplay-progress {
            position: absolute;
            height: calc(100% - 30px);
            width: 100%;
            background-color: white;
            z-index: 2;
            top: 0px;
            left: 0px;
            display: flex;
            flex-wrap: wrap;
            padding: 0;
            overflow: hidden;
            margin-top: var(--ion-safe-area-top, 0);
            position: fixed;
            z-index: 10;
            height: 88px;
            box-shadow: none;
            border-radius: 0;
            box-shadow: $default-box-shadow-3;

            .head {
                height: 36px;
                display: flex;
                width: 100%;
                border-bottom: 1px solid gainsboro;
                display: none;

                .middle {
                    flex-basis: 33.3333%;
                    border-right: 1px solid gainsboro;
                    font-weight: 700;
                    font-size: 1.1em;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                }

                .step {
                    flex-basis: 33.3333%;
                    display: inline-flex;
                    align-items: center;
                    flex-wrap: nowrap;

                    .intervals {
                        display: flex;
                        align-items: center;
                        width: 100%;
                        justify-content: center;

                        p {
                            white-space: nowrap;
                            margin-left: 10px;
                        }

                        ion-icon {
                            flex-basis: unset;
                            margin: 0;
                            color: #333;
                        }
                    }

                    &:first-child {
                        border-right: 1px solid gainsboro;
                    }
                }
            }

            .prog {
                height: 100%;
                flex-basis: 100%;
                display: flex;
                justify-content: space-between;
                position: relative;
                padding-left: 0 !important;
                transition: 0.7s all;

                .step {
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    position: relative;
                    z-index: 2;
                    // border-right: 1px solid #666;
                    background-color: white;
                    overflow: hidden;
                    flex-basis: 20%;
                    flex-wrap: wrap;
                    padding-left: 0 !important;

                    ion-icon {
                        color: #444;
                        font-size: 20px;
                        flex-basis: 100%;
                        margin: 0 auto;
                    }

                    .intervals {
                        flex-basis: 100%;
                        text-align: center;
                        padding-left: 0 !important;

                        ion-icon {
                            margin-bottom: 6px;
                        }

                        p {
                            font-size: 0.86em;
                            line-height: 1em;

                            &:last-child {
                                margin-top: 6px;
                                font-weight: 700;
                            }
                        }
                    }

                    &:nth-child(2), &:nth-child(4) {
                        background-color: whitesmoke;
                    }

                    &[data-active="true"] {
                        background-color: $blue-primary !important;
                        color: white;
                        border-color: transparent;

                        ion-icon {
                            color: white;
                        }
                    }
                }
            }

        }
    }

    .swiper-wrapper-top {
        height: calc(100% - 120px);
        position: relative;
        overflow: hidden;
        z-index: 1;

        .options-alt {
            position: fixed;
            bottom: 15px;
            z-index: 10;
            width: calc(100% - 30px);
            height: 64px;
            left: 15px;

            button {
                background-color: $blue-primary;
                width: 100%;
                padding: 16px;
                color: white;
                border-radius: 8px;
                font-size: 1.1em;
            }

            .autoreview-progress {
                height: 4px;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;

                &>div {
                    position: absolute;
                    height: 4px;
                    background-color: $green;
                    width: 0%;
                    left: 0;
                    top: 0;
                }

            }

            .reviewed {
                position: absolute;
                bottom: 8px;
                left: 0;
                text-align: center;
                left: 15px;
                width: calc(100% - 30px);
            }

            &[data-complete="true"] {
                .autoreview-progress>div {
                    width: 100% !important;
                    animation: none !important;
                }
            }

            @keyframes progresswidth {
                from { width: 0% }
                to { width: 100% }
              }

        }   

        &>div {
            width: 100%;
            height: 100%;
            padding: 0 15px;

            .swiper-container {
                height: 100%;
                overflow: visible;

                .swiper-wrapper {
                    // height: calc(100vh - 269px);

                    .swiper-slide {
                        // background: whitesmoke;
                        // box-shadow: 0 4px 16px rgba(0, 0, 0, 0.29);
                        margin-top: 0px;
                        margin-bottom: 20px;
                        height: calc(100% - 27px);

                        .react-card-flip {
                            height: 100%;

                            .react-card-flipper-dummy {
                                position: relative;
                                height: 100%;
                                width: 100%;

                                .react-card-front-dummy {
                                    position: absolute;
                                    left: 0;
                                    top: 0;
                                    width: 100%;
                                    height: 100%;
                                }

                            }

                            .react-card-flipper {

                                &>div {
                                    background-color: white;
                                    box-shadow: $default-box-shadow-3;
                                    border-radius: 12px;

                                    &>div {
                                        height: 100%;
                                        position: relative;
                                        z-index: 2;
                                        border-radius: 12px;

                                        ion-card, .ioncard {
                                            height: 100%;
                                            margin: 0;
                                            padding: 0;
                                            display: flex;
                                            flex-wrap: wrap;

                                            .options {
                                                align-self: flex-start;
                                                width: 100%;
                                            }

                                            p {
                                                align-self: baseline;
                                                text-align: center;
                                                width: 100%;
                                                font-size: 1.4em;
                                            }

                                        }

                                    }

                                }

                            }

                        }

                        &:last-child {

                            .react-card-flipper>div {
                                box-shadow: none;
                            }
                        }

                    }

                }

            }

            .swiper-pagination, .swiper-button-next, .swiper-button-prev {
                display: none;
            }

        }

    }

    &[data-last-card="true"] {

        .react-card-front {
            box-shadow: none !important;
        }

        .activities-wrapper {
            visibility: hidden;
        }
    }

}

.external-apps-wrapper {
    .external-apps {
        display: flex;

        .img.app {
            width: calc(20% - 10px);
            height: unset;
            min-height: unset;

            img.app-img {
                display: block;
                width: 100%;
                height: auto;
            }

            &:not(:last-child) {
                margin-right: 12px;
            }
        }
    }
}

.autoplay-options {
    overflow: auto;
    max-height: calc(100vh - 84px);
    
    .line {
        min-height: 200px;
        padding: 16px 0 22px 0;

        .opts {
            margin: 0;

            .arrow {
                padding: 4px 0 0px 0;
                width: 40px;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 24px;
                color: #555;
                background-color: white;
            }

            .opt {
                display: flex;
                flex-wrap: wrap;
                // align-items: center;
                background-color: white;
                border-radius: 52px;

                .ico {
                    flex-basis: 40px;
                    background-color: #333;
                    border-radius: 100%;
                    width: 40px;
                    height: 40px;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
                    margin-left: 0;
                    margin-top: 4px;

                    ion-icon {
                        color: white;
                        font-size: 20px;
                    }
                }

                .side {
                    flex-basis: calc(100% - 64px);
                    margin-left: 16px;

                    h3 {
                        margin: 0 0 10px -4px;
                        font-size: 1.1em;
                    }
                    
                    ion-range {
                        padding: 0;
                    }

                    .rc-slider {
                        margin: 0 25px;
                        width: calc(100% - 50px);
                    }

                    .rc-slider-handle {
                        margin: 0;
                        width: 60px;
                        border-radius: 52px;
                        height: 24px;
                        top: -5px;
                        align-items: center;
                        display: inline-flex;
                        justify-content: center;
                        background: $blue-primary;
                        border: none;
                        color: white;
                    }
                }

                &:last-child {
                    background-color: unset;
                }
            }

        }
        
    }

    button {
        border-radius: 100%;
        margin-top: 5px;
    }

}