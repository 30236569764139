$dark-black: #000;
$light-black: #333;
$light-black-2: #555;
$blue-primary: #4D80E4;
$red: #fa4252;
$light-grey: #c0c0c0;
$light-grey-2: #BEBEBE;
$light-grey-3: #e9e9e9;
$green: #2dce91;
$default-box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
$default-box-shadow-2: 0 0px 6px -1px rgba(0, 0, 0, 0.12), 0 2px 4px -1px rgba(0, 0, 0, 0.12);
$default-box-shadow-3: rgba(0, 0, 0, 0.16) 0px 1px 4px;
$dark-blue: #293462;

$tag-red: #fb5b5a;
$tag-yellow: #f7be16;
$tag-orange: #F9844A;
$tag-green: #50d890;
$tag-blue: #617be3;
$tag-pink: #fc7fb2;
$tag-purple: #7579e7;

$grey-1: #ebebeb;