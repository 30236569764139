@import './Colors.scss';

.embedded-card {
    background: white;
    border-radius: 52px;
    margin: 0 15px;
    padding: 8px 18px 8px 12px;
    display: inline-flex;
    align-items: center;
    width: fit-content;

    ion-icon {
        margin-right: 6px;
    }

    span {
        font-weight: 500;
    }
}

.flashcard-editor-modal,
.tag-editor-modal,
.link-editor-modal
{
    border-radius: 24 24px 0 0;
    background-color: #ffffff;
    padding: 12px 0;
    height: 100%;
    max-height: 100vh;
    overflow: auto;

    section.edit {
        padding: 0 12px;

        .quicks {
            height: 44px;
            margin-bottom: 12px;

            .toast {
                background-color: $green;
                height: 100%;
                width: 100%;
                border-radius: 8px;
                color: white;
                font-weight: 600;
                display: flex;
                align-items: center;
                justify-content: center;
                box-shadow: 0px 0px 5px rgba(0,0,0,0.2);

                span {
                    margin-left: 8px;
                }

                ion-icon {
                    color: white;
                    font-size: 1.2em;
                }

            }
    
            .left {
                border-radius: 8px;
                background-color: #F4F4F4;
                padding: 4px;
                display: flex;
                position: relative;
    
                .strength {
                    display: flex;
                    width: 100%;
                }
    
            }
    
            .tile {
                width: 33.3333%;
                height: 100%;
                border-radius: 6px;
                display: inline-flex;
                align-items: center;
                padding: 6px;
                justify-content: center;

                &.star {
                    width: 36px;
                }
    
                ion-icon {
                    display: inline-block;
                    color: white;
                    font-size: 24px;
                    position: relative;
                }
    
                label {
                    padding-left: 8px;
                    display: inline-block;
                    font-size: 0.8em;
                    font-weight: 600;
                    text-align: center;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }
    
                &[data-selected="true"] {
                    background-color: white;
                }
    
            }
    
        }
    
        .term, .definition, .customs {
            margin-bottom: 12px;
            position: relative;

            .aggregator-btn {
                position: absolute;
                top: 6px;
                right: 6px;
                background-color: white;
                border-radius: 8px;
                box-shadow: $default-box-shadow;
                align-items: center;
                justify-content: center;
                display: inline-flex;
                width: 24px;
                height: 24px;
            }

            .similarities {
                white-space: nowrap;
                overflow: auto;
                
                &>div {
                    background-color: $blue-primary;
                    display: inline-block;
                    margin: 10px 10px 10px 0;
                    border-radius: 52px;
                    padding: 2px 10px;
                    color: white;
                    font-size: 14px;

                    span {
                        font-size: 12px;
                        margin-left: 6px;
                        font-weight: 300;
                    }
                }
            }

            &.customs {
                margin-bottom: 0;
            }

            .custom {
                display: flex;
                align-items: center;
                margin-bottom: 12px;

                ion-icon {
                    color: #444;
                    font-size: 28px;
                    margin-left: 8px;
                }
            }
    
            textarea {
                padding: 8px 36px 8px 16px;
                width: 100%;
                border: none;
                background-color: #F8F8F8;
                display: block;
                font-size: 0.9em;
                line-height: 1.5em;
                border-radius: 8px;
                resize: none;
            }
    
        }

        .links {

            ion-icon {
                margin: -4px 4px -4px 0px;
                color: white;
                font-size: 20px;
            }

            ion-label {
                max-width: 100px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

        }

        .tags {
            margin-bottom: 3px;
            margin-top: 2px;
            width: 100%;
            border: none;
            display: block;
            font-size: 0.9em;
            border-radius: 8px;
            resize: none;
            width: 100%;
            overflow: auto;

            ion-chip {
                padding: 6px 8px;

                &:last-child {
                    margin-right: 0;
                }

                // &:first-child {
                //     color: #333;
                //     background-color: #F8F8F8;
                // }
            
            }

        }

        .images {
            height: 44px;
            margin-bottom: 10px;

            &>div {
                margin: 0 10px 0 0;
                display: inline-block;
                border-radius: 4px;
                overflow: hidden;
                width: 44px;
                height: 44px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }
        }
    
        .opts {
            // height: 44px;
            // display: flex;
            // align-items: center;
            // justify-content: space-between;

            .right {
                margin-top: 10px;
            }
    
            button {
                background: #F8F8F8;
                height: 44px;
                flex-basis: calc(33.3333% - 7px);
                border-radius: 8px;
                padding: 0;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                position: relative;
                overflow: unset;
                margin-bottom: 0;

                ion-label {
                    color: #333;
                    font-size: 13px; // 0.9em;
                    margin-left: 6px;
                }
    
                ion-icon {
                    // font-size: 1.5em;
                    font-size: 18px;
                    color: #444;
                }

                .uploading {
                    animation: rotation 2s infinite linear;
                }

                .img {
                    background-size: cover;
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                }

                @keyframes rotation {
                    from {
                            -webkit-transform: rotate(0deg);
                    }
                    to {
                            -webkit-transform: rotate(359deg);
                    }
                }
    
                &.save {
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #444;
                    width: auto;
                    color: white;
                    padding: 0 12px 0 10px;
                    min-width: 90px;
                    // color: white;
                    // width: 96px;

                    ion-icon {
                        font-size: 1.4rem;
                        color: white;
                        display: inline-block;
                        margin-right: 8px;
                    }

                    span {
                        display: inline-block;
                        font-size: 13px; // 1.1em;
                    }

                    &[data-has-changed="true"] {
                        background-color: $green;
                    }

                }

                &.star {

                    &[data-selected="true"] {
                        // background-color: $tag-yellow;
                        border: 2px solid $tag-yellow;

                        ion-icon {
                            color: $tag-yellow; // white
                        }

                    }
                
                }
    
            }
    
            .right {

                button {
                    width: 100%;
                }
    
            }
    
            .left {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                
                button:nth-child(1),
                button:nth-child(2),
                button:nth-child(3)
                {
                    margin-bottom: 10px;
                }

            }
    
        }

    }

    hr {
        background: #e5e5e5;
        margin: 12px auto;
    }

    section.stats {
        position: relative;

        &.mobile {
            background-color: red;
        }

        .history {
            margin-top: 20px;
            padding: 0 12px;

            .item {
                display: flex;
                align-items: center;
                margin-bottom: 16px;

                .ci {
                    width: 36px;
                    flex-basis: 36px;
                    height: 36px;
                    border-radius: 100%;
                    // background-color: #666 !important; // $blue-primary !important;
                    display: inline-flex;
                    align-items: center;
                    justify-content: center;
    
                    ion-icon {
                        font-size: 1.1em;
                        color: white;
                    }
    
                }
    
                .meta {
                    flex-basis: calc(100% - 36px);
                    padding-left: 12px;
    
                    h2 {
                        font-weight: 700;
                        font-size: 1em;
                        line-height: 0.9em;
                        margin-bottom: 4px;
                    }
    
                    p {
                        font-weight: 500;
                        font-size: 0.9em;
                        line-height: 1em;
                    }
    
                }

            }

        }

        .nums-wrapper {
            width: 100%;
            overflow-y: hidden;
            overflow-x: auto;
            margin-bottom: 10px;
            position: relative;
            padding-left: 12px;
            padding-right: 12px;

            .top-shadow:before {
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                bottom: auto;
                width: 16px;
                background-image: linear-gradient(to right, rgba(60, 60, 60, 0.25), rgba(60, 60, 60, 0));
                z-index: 1;
                left: 0;
            }

            .bottom-shadow {
                position: absolute;
                right: 0px;
                top: 0px;
                width: 5px;
                height: 100%;
            }
        
            .bottom-shadow:after {
                content: "";
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: auto;
                width: 16px;
                background-image: linear-gradient(to left, rgba(60, 60, 60, 0.25), rgba(60, 60, 60, 0));
                z-index: 1;
            }

            .nums {
                // display: flex;
                // flex-wrap: wrap;
                // justify-content: space-between;
                white-space: nowrap;
                width: 1102px;
                
                .c {
                    background-color: #F4F4F4;
                    float: left;
                    width: 90px;
                    border-radius: 8px;
                    padding: 12px 4px;
                    text-align: center;
                    margin-right: 10px;

                    .ci {
                        width: 28px;
                        height: 28px;
                        flex-basis: 28px;
                        border-radius: 100%;
                        display: inline-flex;
                        align-items: center;
                        margin-bottom: 10px;
                        justify-content: center;
                        
                        ion-icon {
                            color: white;
                            font-size: 1em;
                        }

                    }   

                    .side {

                        label {
                            font-weight: 600;
                            font-size: 0.8em;
                            display: block;
                            line-height: 0.8em;
                            overflow: hidden;
                        }
    
                        p {
                            font-weight: 700;
                            font-size: 1.2em;
                            line-height: 1.2em;
                            margin-bottom: 2px;
                        }

                    }

                    &:last-child {
                        margin-right: 0;
                    }

                }

            }
        
        }

    }

    #image-upload-input {
        // position: absolute;
        // left: 400px;
        // top: 400px;
        display: none;
    }

    &[data-keyboard-showing="true"] {
        margin-bottom: 0px !important;
        padding-bottom: 15px !important;
    }

    @media (max-width: 724px) {

        .stats {

            .nums-wrapper {
                overflow: hidden !important;
                margin-bottom: 2px !important;

                .nums {
                    width: 100% !important;
                    display: flex;

                    .c {
                        width: auto !important;
                        align-items: center !important;
                        padding: 6px !important;
                        border-radius: 52px !important;
                        margin-bottom: 8px !important;

                        .ci {
                            margin: 0 !important;
                        }

                        .side {
                            margin: 0 4px 0 8px !important;

                            label {
                                display: none !important;
                            }
                        }

                        &:not(:last-child) {
                            display: inline-flex !important;
                        }

                        &:last-child {
                            position: absolute;
                            top: 0;
                            right: 0;
                            margin: 0 !important;

                            .c {
                                border-radius: 52px 0 0 52px !important;
                            }

                            .side {
                                display: none !important;
                            }
                        }
                    }
                }

                &[data-show-history="true"] {

                    .nums {
                        flex-wrap: wrap;

                        .c {

                            .side {
                                text-align: left;
                                margin: 0 8px 0 8px !important;
                            }

                            &:last-child {
                                display: none;
                            }

                            p, label {
                                display: block !important;
                            }

                            p {
                                line-height: 1em !important;
                            }
                        }
                    }
                }
            }
        }
    }

    @media (min-width: 724px) {

        .nums-wrapper {
            margin-bottom: 0 !important;
            
            .nums {
                width: auto !important;

                .c {
                    margin-bottom: 10px !important;
                    width: 94px !important;

                    &:nth-child(6), 
                    &:nth-child(11) {
                        margin-right: 0 !important;
                    }

                    &:nth-child(11) {
                        width: 198px !important;

                        .side label {
                            margin-left: auto !important;
                            margin-right: auto !important;
                        }

                    }

                }

            }

        }

    }

}

.MuiPaper-root {
    background-color: transparent !important;
    box-shadow: none !important;
}