@import 'Colors.scss';

.goal-progress {
    background-color: $blue-primary;
    padding: 10px;
    // border-radius: 8px;
    position: relative;
    box-shadow: $default-box-shadow-2;
    background-repeat: no-repeat;
    background-size: calc(100% + 20px);
    background-position-y: 61px;
    background-position-x: -16px;
    margin: 0;
    border-radius: 8px;
    
    .help {
        padding: 0 20px 10px 20px;
        text-align: center;
        color: white;
    }

    & > label {
        color: white;
        font-weight: 500;
        position: absolute;
        top: 13px;
        right: 15px;

        button {
            background: white;
            color: black;
            padding: 4px 8px;
            font-size: 13px;
            margin-left: 4px;
            border-radius: 12px;
            position: relative;
            top: -1px;
        }

    }

    h1 {
        color: white;
        font-size: 1.2em;
        margin: 0 0 20px 0;
        padding-left: 5px;
        padding-top: 5px;
    }

    .days {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        padding: 0 5px;

        .day {
            text-align: center;
            display: flex;
            flex-wrap: wrap;
            position: relative;
    
            .label {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-weight: 700;
                color: white;
            }
    
            .circle {
                background-color: $blue-primary;
                border-radius: 100%;
    
                &[data-today="true"] {
                    border-radius: 100%;
                    background-color: #ff9d33;
                }

            }
    
            &:not(:nth-child(7)) {
                margin-right: 8px;
            }
    
        }

    }

    .today {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 15px;

        button {
            color: white;
            margin-top: 2px;
            font-weight: 400;
        }

    }

    .activities {
        padding: 0;
        background-color: transparent !important;

        &>div {
            background-color: white !important;
            padding: 8px !important;
            word-break: break-word;

            ion-icon, p, ion-label {
                color: #333 !important;
                text-align: left !important;
            }

            ion-icon {
                font-size: 1.2em;
                margin: 0;
            }

            p {
                font-size: 1em;
                margin-top: 0;;
            }

            ion-label {
                font-size: 0.9em;
            }

        }   

        // #continue-last-pracitce-btn {
        //    flex-basis: 100% !important;
        //    display: flex;
        //    flex-wrap: nowrap;
        //    align-items: center;
        //    justify-content: left !important;
        //    margin-bottom: 10px;

        //    ion-icon {
        //         flex-basis: 20px;
        //     }

        //     &>div {
        //         flex-basis: calc(100% - 20px);
        //     }

        //     ion-label {
        //         padding: 0 0 0 5px !important;
        //         margin: 0 !important;
        //     }

        // }

        // &>div:not(#continue-last-pracitce-btn) {
        &>div {
            flex-basis: 33.3333% !important;
            display: inline-flex !important;
            align-items: center !important;
            flex-wrap: nowrap;
            justify-content: space-between;

            ion-icon {
                flex-basis: 20px;
            }

            .badge {
                font-size: 1.4em;
                margin-bottom: 0;
            }

            ion-label {
                font-size: 0.86em;
                font-weight: 500 !important;
                margin: 0 !important;
            }

            &>div {
                flex-basis: calc(100% - 20px);
                padding-left: 5px;
            }

        }

    }

}