@import 'Colors.scss';

.ion-page[data-page="studysets"] {

    // ion-toolbar {
    //     height: 0;
    //     overflow: hidden;
    // }

    .search-options {
        background-color: whitesmoke;
        padding: 15px 15px 0 15px;

        ion-searchbar {
            padding-inline-start: 0px;
            padding-inline-end: 0px;
            padding: 0;
            height: 36px;
            margin-bottom: 10px;
        }

        .tags {
            white-space: nowrap;
            margin-top: 0;
            padding: 0 12px 2px 0;
            overflow: hidden;
            overflow-x: scroll;

            ion-chip {
                margin-bottom: 0;
            }
        }

    }

    .search-wrapper {
        height: calc(100% - 60px);
        overflow: auto;
        background: whitesmoke;
        padding: 5px 15px 100px 15px;

        .not-enough-keywords {
            height: 160px;
            display: flex;
            align-items: center;
    
            h2 {
                text-align: center;
                flex-basis: 100%;
                font-weight: 500;
                font-size: 1.1em;
            }
    
        }

    }

    .sets {
        min-height: calc(100% - 186px);
        background-color: whitesmoke;
        padding-bottom: 100px;

        .flashcard:first-child {
            margin-top: 0;
        }

        ion-card {
            padding: 12px;
            margin-bottom: 0px;
            margin-top: 16px;
            box-shadow: none;
            background-color: white;
            position: relative;

            .progress {
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                height: 6px;
                width: 100%;
                display: flex;
                margin-bottom: 10px;

                div {
                    height: 6px;
                    display: inline-block;
                }

            }

            .tags ion-icon {
                color: white !important;
            }

            p {
                font-size: 1.2em;
                line-height: 1.2em;
            }

            .tags {
                margin-top: 4px;
            }

        }

        // .sync-status {
        //     display: flex;
        //     align-items: center;
        //     justify-content: center;
        //     padding: 12px 20px 0 20px;
        //     height: 38px;

        //     p {
        //         margin-left: 4px;
        //     }

        //     ion-icon {
        //         position: static;
        //     }

        // }

    }

    .tags {
        margin-top: 12px;
    }

    .flashcard {
        padding-bottom: 4px !important;

        ion-icon.sync {
            position: absolute;
            top: 12px;
            right: 12px;
            font-size: 1.6em;
    
            &.rotate {
                animation: rotating 2s linear infinite;
            }
        
        }

    }

    @keyframes rotating {
        from {
            -ms-transform: rotate(0deg);
            -moz-transform: rotate(0deg);
            -webkit-transform: rotate(0deg);
            -o-transform: rotate(0deg);
            transform: rotate(0deg);
        }
        to {
            -ms-transform: rotate(360deg);
            -moz-transform: rotate(360deg);
            -webkit-transform: rotate(360deg);
            -o-transform: rotate(360deg);
            transform: rotate(360deg);
        }
    }

    ion-chip {
        padding: 6px 10px;

        span {
            margin-left: 4px;
            font-size: 0.9em;
            font-weight: 800;
        }

        ion-icon {
            color: white;
            font-size: 1.4em;
            margin-right: 4px;
        }

    }

    .not-activated-notice {
        margin-top: 20px;
        background-color: $blue-primary;
        border-radius: 8px;
        padding: 16px;
        color: white;
        box-shadow: $default-box-shadow;

        h2 {
            font-size: 1.1em;
            margin-top: 0;
            font-weight: 800;
            margin-bottom: 8px;
        }

        p {
            line-height: 1.2em;
            font-size: 1.1em;
            margin-bottom: 16px;
        }

        button {
            display: block;
            background-color: white;
            color: $blue-primary;
            padding: 14px 20px;
            text-align: center;
            border-radius: 8px;
            text-transform: uppercase;
            width: 100%;
            font-weight: 700;
            font-size: 0.9em;
        }

        &.goals {
            margin-top: -8px;
            text-align: center;
            display: flex;
            position: relative;
            align-items: center;

            &>.side {

                h2, p {
                    text-align: left;
                }

                h2 {
                    margin-bottom: 8px;
                    font-size: 1em;
                    padding-right: 24px;
                }

                p {
                    margin-bottom: 0;
                    font-size: 0.96em;
                    line-height: 1.3em;
                }

            }

            ion-icon {
                position: absolute;
                top: 0;
                right: 0;
                font-size: 1.4em;
                top: 14px;
                right: 14px;
            }

            img {
                width: 92px;
                margin-bottom: 0;
                padding-right: 20px;
                height: min-intrinsic;
            }

        }

    }

}

.activities-wrapper.select-type {

    &>div {

        &>div {
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 12px;
            overflow: hidden;

            img {
                width: calc(100% - 160px);
            }

            div {
                width: 160px;
                color: white;
                padding: 12px;

                p {
                    padding: 0;
                    text-align: left;
                }

            }

            &:not(:last-child) {
                margin-bottom: 16px;
            }

        }

    }

}

.share-modal {

    .content {
        padding: 0 20px 20px 20px;

        img {
            display: block;
            max-width: 500px;
            width: 100%;
            margin: 15px auto 25px auto;
        }

        .opts {

            textarea {
                resize: none;
                width: 100%;
                border-radius: 10px;
                border: none;
                padding: 7px 10px;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 10px;
            }
            
            p {
                background: whitesmoke;
                padding: 10px 14px;
                border-radius: 9px;
                border: 2px dashed $blue-primary;
                position: relative;
                margin-bottom: 15px;
                text-align: center;

                ion-icon {
                    font-size: 28px;
                    position: absolute;
                    left: 12px;
                    top: 9px;
                }

                button {
                    padding: 10px 12px;
                    font-size: 1.2em;
                    line-height: 0.9em;
                    margin-top: 6px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .icons {
                        display: inline-flex;
                        
                        ion-icon {
                            position: static;
                            
                            &:not(:last-child) {
                                margin-right: 6px;
                            }
                        }
                    }

                    span {

                    }
                }
            }
        }

        .share-disclaimer {

            &>div {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 20px;

                ion-icon {
                    font-size: 32px;
                    flex-basis: 40px;
                    color: $green;
                }

                p {
                    flex-basis: calc(100% - 40px);
                    padding-left: 20px;
                }

                &:last-child {
                    margin-bottom: 25px;
                    
                    ion-icon {
                        color: $red;
                    }
                }
            }
        }

        button {
            margin: 0;
            width: 100%;
        }
    }
}