@import './Colors.scss';

.contact-us {
    background-color: $blue-primary;
    padding: 15px;
    align-items: center;
    border-radius: 12px;
    margin: 20px 15px 0 15px;

    p#p {
        text-align: center;
        font-weight: 500;
        font-size: 1rem;
        line-height: 1.32em;
        margin-bottom: 18px;
        color: white !important;
        margin: 2px 0 18px 0;
        padding: 0;
    }

    .social {
        text-align: center;
        margin-bottom: 10px;

        ion-icon {
            font-size: 2em;
            margin-right: 12px;
            color: white;

            &:last-child {
                margin-right: 0;
            }

        }

    }

    .options {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        max-width: 480px;
        margin: auto;

        button {
            padding: 0 0;
            border-radius: 8px;
            font-size: 0.96em;
            font-weight: 600;
            width: 100%;

            span {

            }

            .circle {
                display: block;
                width: 56px;
                height: 56px;
                background-color: white;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                border-radius: 100%;

                ion-icon {
                    color: $blue-primary;
                    font-size: 28px;
                }
            }

            span {
                color: white;
                display: block;
                margin-top: 10px;
                min-height: 32px;
                font-size: 15px;
                line-height: 18px;
            }

        }

        // &.first-options {
        //     margin-bottom: 13px;

        //     button {
        //         width: calc(50% - 7.5px);
        //         display: inline-flex;
        //         align-items: center;
        //         justify-content: center;

        //         ion-icon {
        //             margin-right: 6px;
        //         }
                
        //         &:first-child {
        //             margin-right: 13px;
        //         }
        //     }
        // }

    }

}